import { getStaticResourceUrl } from 'egenie-common';

export const api = {
  // 静态资源
  oss: `${getStaticResourceUrl('pc/ts/egenie-common')}`,

  // 公用
  getDict: '/api/gms/pc/common/getDict', // 根据字典值获取公共列表
  getCategoryList: '/api/gms/pc/common/getCategoryList', // 获取类目列表
  uploadFile: '/api/baseinfo/rest/oss/uploadfile', // 图片上传
  getAliYunPolicy: '/api/goodsPic/rest/anon/policy/getAliYunPolicyAnon', // 获取阿里云配置
  getProtocal: '/api/boss/baseinfo/rest/agreement/anon/queryHtmlById', // 查询协议
  loginCheck: '/api/iac/anon/session/check', // 检验登录状态
  queryCategory: '/api/mall/rest/square/anon/categoryList', // 请求分类

  // 档口、档口详情
  stallStatus: '/api/mall/rest/dynamic/dynamicPage', // 分页查询档口动态
  cooperationStall: '/api/mall/rest/dynamic/queryVendorInfo', // 分页查询合作的档口
  verdorCategory: '/api/mall/rest/square/categoryList', // 门店经营范围一级列表(主营)
  styleDict: '/api/mall/rest/style/dict', // 查询风格字典
  categoryDict: '/api/mall/rest/square/queryMoreCategory', // 查询前端分类
  vendorDetail: '/api/mall/rest/dynamic/vendorDetail', // 获取指定的店铺详细信息
  recommendStall: '/api/mall/rest/dynamic/vendorDynamicPage', // 获取推荐档口
  productList: '/api/mall/rest/goods/product/pageGoods', // 获取档口的全部商品
  getVendorTag: '/api/mall/baokuan/rest/vendor/dynamic/vendor/tag', // 查询所有档口标签
  getLabelList: '/api/mall/rest/dynamic/tag/list', // 获取标签列表
  bindLabel: '/api/mall/rest/dynamic/binding/vendor', // 档口绑定标签
  getAllVendor: '/api/mall/rest/dynamic/allVendorPage', // 合作档口查询全部档口
  getGoodsSkuInfo: '/api/mall/rest/goods/queryShoppingCartGoodsSkuInfo', // 查询购物车sku信息
  addToCart: '/api/mall/rest/shoppingCart/addShoppingCart', // 加入购物车

  // 专享推款
  getRecommendNum: '/api/mall/rest/dynamic/message/goodsNum', // 查询近7天推款数
  getRecommendVendorList: '/api/mall/rest/dynamic/message/vendors', // 查询推款的档口
  getVendorLabel: '/api/mall/rest/dynamic/message/vendor/tag', // 查询所有标签
  getDynamicList: '/api/mall/rest/dynamic/message/page', // 查询动态列表
  getRecommendStyleList: '/api/mall/rest/dynamic/message/goods/pageV2', // 查询款式列表
  getCooperationVendorNum: '/api/mall/rest/dynamic/message/vendor/count', // 查询合作档口数
  getPicDownloadInfo: '/api/mall/rest/goods/getPicGroupDownLoadInfoByGoodsId', // 查询下载内容

  // 款式详情
  getGoodsHead: '/api/mall/rest/square/anon/',
  getStyleGoodsDetail: '/recommend/detail/info', // 查询详情数据
  collectionOrNot: '/api/mall/rest/goods/collection/addOrCancel', // 收藏款式Or取消
  getMiniCodeUrl: '/api/iac/third/anon/qrcode/info',

  // 选款和复制平台商品
  getShopAndStrategy: '/api/gms/pc/common/getDict', // 获取店铺和策略信息
  getClassifyShop: '/api/gms/pc/common/getClassifyShop', // 获取不同平台店铺列表
  getPictures: '/api/mall/rest/goods/getAllMainPictureByGoodsId', // 获取所有图库图片
  getStyleMainPics: '/api/gms/pc/style/getPicListById', // 款式管理获取主图
  getGoodsMainPics: '/api/gms/goods/getGoodsMainPics', // 平台商品管理获取主图
  submitCopyGoods: '/api/gms/goods/new/copyGoods', // 复制平台商品提交
  submitPublish: '/api/mall/rest/goods/waiting/shelve', // 选款提交

  // 下载图片包
  getGroupPic: '/api/gms/goods/getGroupPicDownload', // 获取可下载组图信息
  recordDownloadNum: '/api/mall/rest/dynamic/addDownloadPicNum', // 下载图片包埋点

  // 我的款式
  styleListQuery: '/api/gms/pc/style/page', // 分页查询款式列表页面
  getPicListById: '/api/gms/pc/style/getPicListById', // 根据款式id获取图片信息
  getCreateGoodsByStyleId: '/api/gms/pc/style/getCreateGoodsByStyleId', // 获取创建平台商品信息
  createGoods: '/api/gms/pc/style/createGoods', // 创建平台商品
  getMatchList: '/api/gms/pc/style/getMatchList', // 获取关联列表
  getVendorGoodsList: '/api/gms/pc/style/pageVendorTenantGoods', // 分页获取获取供应商款式列表
  getWaitMatchList: '/api/gms/pc/style/pageWaitMatchList', // 获取待关联列表
  matchStyle: '/api/gms/pc/style/match', // 关联系统款式与档口款式

  // 店铺商品管理
  getGoodsList: '/api/gms/goods/goodsPage', // 查询主表数据
  gmsCancelMatch: '/api/gms/goods/cancelMatchV2', // 取消关联
  gmsUpdateSaleStatus: '/api/gms/goods/updateSaleStatus', // 上架
  pageWaitMatchList: '/api/gms/goods/pageWaitMatchList', // 获取待关联商品列表
  getPosVendorList: '/api/mall/operation/rest/admin/list', // 店铺列表
  getOperationShopList: '/api/mall/operation/rest/rest/common/enable/shop/item/page/list', // 分页查询已开启档口下拉列表
  getSkuMatchList: '/api/gms/goods/getCandidateMatchList', // 获取关联商品的的sku信息
  gmsGoodsMatch: '/api/gms/goods/matchV2', // 关联sku
  queryMatchDetails: '/api/gms/goods/queryMatchDetails', // 根据商品id获取关联详情
  listPosGoodsSku: '/api/gms/goods/listPosGoodsSku4Match', // 根据pos商品id获取sku列表详情
  skuMatch: '/api/gms/goods/skuMatch', // 单个sku关联
  cancelSkuMatch: '/api/gms/goods/cancelSkuMatch', // 取消单个sku关联

  getLifeCycle: '/api/gms/goods/goodsCycle', // 获取生命周期数据
  getGoodsSalesStat: '/api/gms/goods/goodsSalesStat', // 数据概览
  getDefaultShop: '/api/gms/goods/defaultShopId', // 查询上次选中店铺
  getLifeCycleParam: '/api/gms/lifeCycleConfig/getByShopId', // 查询生命周期参数配置
  saveLifeCycleParam: '/api/gms/lifeCycleConfig/edit', // 保存生命周期参数配置
  lifeCycleDefaultConfig: '/api/gms/lifeCycleConfig/defaultConfig', // 生命周期参数 恢复默认值
  queryEnabledStrategy: '/api/gms/strategyTask/strategyList', // 查询所有启用策略
  copyGoods: '/api/gms/goods/copyGoods', // 复制平台商品
  putAway: '/api/gms/goods/putAway', // 上架
  soldOut: '/api/gms/goods/soldOut', // 下架
  queryTaskFlag: '/api/gms/strategyTask/taskFlag', // 商品上架是否已完成策略任务

  // 商品详情
  goodsSoldOut: '/api/gms/goods/soldOut', // 商品下架
  getGoodsDetail: '/api/gms/goods/gmsGoodsDetail', // 获取商品详情
  getGoodsSaleTrend: '/api/gms/goods/salesTrend', // 获取商品销售数据
  getSkuData: '/api/gms/goods/sales/skuDistrib', // 获取sku分布数据
  getStrategicTask: '/api/gms/strategyTask/queryTaskByGmsGoodsId', // 获取策略信息
  getSkuPrice: '/api/mall/rest/goods/{goodsId}/sku/price', // 获取sku对应的价格
  submitInfo: '/api/mall/rest/goods/pc/shelve', // 提交上款到店铺
  copyInfo: '/api/gms/goods/new/copyGoods', // 复制平台商品
  getGoodsSkuList: '/api/gms/goods/listGoodsSku', // 获取对应gmsGoodsId的sku列表

  // 策略相关
  editGoodsTask: '/api/gms/strategyTask/editGoodsTask', // 分配|编辑策略保存接口
  queryCurrentProductTask: '/api/gms/strategyTask/queryTaskByGmsGoodsId', // 编辑策略  查询当前商品所有策略
  getTaskCount: '/api/gms/strategyTask/taskCount', // 策略任务 查询已完成和任务总数
  queryAllStrategy: '/api/gms/strategy/queryAllStrategy', // 查询所有策略
  queryGoodsTask: '/api/gms/strategyTask/goodsTaskPage', // 策略任务 查询商品看板列表
  editStrategyStatus: '/api/gms/strategyTask/editStatus', // 策略任务 勾选复选框  改变策略状态
  queryStrategyTask: '/api/gms/strategyTask/strategyTaskPage', // 策略任务 查询策略看板数据
  editSingleStrategy: '/api/gms/strategy/edit', // 策略管理 修改单个策略信息
  deleteStrategyCheck: '/api/gms/strategy/deleteCheck', // 删除策略前，校验当前策略是否还有未完成任务
  deleteStrategy: '/api/gms/strategy/delete', // 策略管理 删除策略
  newStrategy: '/api/gms/strategy/add', // 策略管理  新增策略

  // dashboard
  findDict: '/api/gim/dict/findDict', // 字典
  tenantRechangeStatistics: '/api/tenant/tenantRecharge/statistics', // 租户剩余单量信息
  tenantRelevantInfo: '/api/tenant/tenantRecharge/tenantRelevantInfo', // 租户信息
  tenantRechargePay: '/api/tenant/tenantRecharge/pay', // 支付宝支付
  findAllRechargeConfig: '/api/tenant/tenantRecharge/findAllRechargeConfig', // 单量金额表
  getWarehouses: '/api/pos/baseinfo/rest/shop/findPosShopWarehoue',
  getRestData: '/api/statSaleOrder/orderCount',
  user: '/api/iac/dashboard/user',
  shopExpireList: '/api/baseinfo/rest/shop/expire',
  shopAgain: '/api/dts/auth/shop/again',
  delivergoods: '/api/bi/erp_current/orders/delivergoods/6/7', // 发货量
  salegoods: '/api/bi/erp_current/orders/indexTimeline/6/7', // 销量
  todaygoods: '/api/bi/erp_current/orders/delivergoods/11/24', // 当天发货
  payedAndNotDelivery: '/api/bi/erp_current/rss/isPaidNotCheck/queryIndex', // 已付款未发货
  getSevenDaysTopProducts: '/api/bi/saleStatistics/getSevenDaysTopProducts', // 销售前几名
  getWaybillNotice: '/api/wms/rest/waybill/getWaybillNotice', //
  queryCommonInfo: '/api/baseinfo/rest/homepage/queryCommonInfo', // 查询公告、更新、快捷方式
  commitFeedback: '/api/baseinfo/rest/homepage/commitFeedback', // 反馈
  saveShortcut: '/api/baseinfo/rest/homepage/saveShortcut', // 修改快捷方式
  getMenus: '/api/iac/resource/dashboard/queryAllMenu',
  orderTodo: '/api/oms/rest/monitor/undeliveredAmounts', // 订单待办
  deliveryTodo: '/api/bi/erp_current/menu', // 发货单待办

  // 上架淘宝
  validePermission: '/api/gms/pc/common/checkShopAuth', // 校验上架权限
  gotoAuth: '/api/baseinfo/rest/shop/auth', // 去授权
  getTaobaoInfo: '/api/gms/goods/platform/taobao/getGoodsPutOnShelfDetail', // 获取淘宝上架信息
  getTaobaoDeliveryTemplates: '/api/gms/goods/platform/taobao/getDeliveryTemplates', // 获取淘宝运费模版
  saveTaobaoGoodsSetOnShelf: '/api/gms/goods/platform/taobao/saveGoodsPutOnShelf', // 保存
  submitTaobaoGoodsSetOnShelf: '/api/gms/goods/platform/taobao/goodsPutonShelf', // 提交
  getShopCatogoryTaobao: '/api/gms/goods/platform/taobao/getSellerCat', // 获取淘宝店铺分类

  // 上架天猫
  getTianmaoInfo: '/api/gms/goods/goodsSetOnShelfDetail', // 获取天猫上架信息
  getProvinceList: '/api/baseinfo/rest/province/getAll', // 获取省份列表
  getCityList: '/api/baseinfo/rest/city/queryByProvinceId', // 获取城市列表
  getAllWashIcons: '/api/washIcon/getAllWashIcon', // 水洗图标
  getSizeTpl: '/api/gms/goods/platform/tmall/getSizemappingTemplates', // 模板列表
  getCatAndDeliveryTemplates: '/api/gms/pc/common/getCatAndDeliveryTemplates', // 店铺中分类
  saveSku: '/api/gms/goods/saveSku', // 保存第一步sku信息
  saveGoodsSetOnShelf: '/api/gms/goods/tmall/saveGoodsSetOnShelf', // 保存
  submitGoodsSetOnShelf: '/api/gms/goods/tmall/putOnShelf', // 提交
  getCategory: '/api/pos/goods/rest/gim/category/findOneLevel', // 获取类目
  getGoodsPutOnShelfDetail: '/api/gms/goods/platform/tmall/getGoodsPutOnShelfDetail', // 获取天猫step2
  getPosGoodsGroupPics: '/api/gms/goods/getPosGoodsGroupPics',
  getGoodsCategoryFullName: '/api/gms/goods/getGoodsCategoryFullName',
  goodsPutonShelf: '/api/gms/goods/platform/tmall/goodsPutonShelf',
  saveGoodsPutOnShelf: '/api/gms/goods/platform/tmall/saveGoodsPutOnShelf',
  saveProductPutOnShelf: '/api/gms/goods/platform/tmall/saveProductPutOnShelf',
  publishProduct: '/api/gms/goods/platform/tmall/publishProduct',
  getCategoryProps: '/api/pos/goods/rest/goodsBusinessPropsSetting/findBusinessPropsSettingAndRecognizeValue',
  getProductPutOnShelfFields: '/api/gms/goods/platform/tmall/getProductPutOnShelfFields',
  queryItemCatsByParentCid: '/api/pos/goods/rest/gim/category/findOneLevel',
  queryItemCatsAuthorize: '/api/gms/goods/platform/tmall/queryItemCatsAuthorize',
  getCategoryHistory: '/api/pos/goods/rest/gim/category/history',
  searchByLeafCategoryName: '/api/pos/goods/rest/category/searchByLeafCategoryName',
  getEditorInfo: '/api/gms/goods/queryGoodsHtml',

  // 淘宝、天猫 下架
  delisting: '/api/gms/goods/delisting', // 下架

  // 选款订单
  queryPageOrder: '/api/mall/rest/saleOrder/pageOrder', // 主表查询
  queryOrderStatus: '/api/mall/rest/saleOrder/status', // 订单状态列表
  queryOrderDetail: '/api/mall/rest/saleOrder/findDetail', // 订单详情
  getVendorList: '/api/mall/rest/saleOrder/getSimpleVendorInfo', // 获取档口列表
  getUsersList: '/api/mall/rest/saleOrder/getUsers', // 获取下单账号
  confirmReceive: '/api/mall/rest/saleOrder/confirmReceive', // 确认收货
  cancelOrder: '/api/mall/rest/saleOrder/cancelOrder', // 取消订单
  deleteOrder: '/api/mall/rest/saleOrder/deleteOrder', // 删除订单
  queryLogistic: '/api/mall/rest/saleOrder/findCourierOrder', // 请求物流信息

  // 确认下单
  createOrderByCart: '/api/mall/rest/shoppingCart/createOrderByCartV2', // 进货车下单
  createOrderBySku: '/api/mall/rest/saleOrder/createOrderBySku', // 款式详情下单
  getFright: '/api/mall/rest/post/findPost', // 获取运费
  // parsingAddress: '/api/mall/rest/receiverAddress/addressParsing', // 地址解析
  parsingAddress: '/api/infrastructure/address/parse', // 地址解析
  getPosFee: '/api/mall/rest/post/getPosFee', // 获取运费

  // 收银台
  getPayCashier: '/api/pay/web/cashier/pc/getPayCashierByCode', // 获取收银台信息
  // queryBalance: '/api/pay/rest/member/queryBalance', // 查询余额
  checkPasswordExist: '/api/pay/web/password/checkExist', // 获取是否有支付密码
  getEncryptKey: '/api/pay/web/password/encryptKey', // 设置密码/验证密码
  cashierPayAsync: ' /api/pay/web/cashier/pc/pay/async', // 支付（异步，批量代发用）
  cashierPay: '/api/pay/web/cashier/pc/pay', // 收银台支付
  queryPcCashierFinanceVo: '/api/pay/web/cashier/pc/queryPcCashierFinanceVo', // 获取依链白条分期信息
  queryChannelOrderStatus: '/api/pay/web/cashier/pc/queryChannelOrderStatus', // 获取渠道单状态

  // 地址管理
  queryAddressById: '/api/mall/rest/receiverAddress/queryMallReceiverAddressById', // 根据id查询地址详情
  addReceiverAddress: '/api/mall/rest/receiverAddress/addReceiverAddress', // 添加
  deleteAddress: '/api/mall/rest/receiverAddress/deleteReceiverAddressByIds', // 删除
  updateReceiverAddress: '/api/mall/rest/receiverAddress/updateReceiverAddress', // 修改
  updateDefaultAddress: '/api/mall/rest/receiverAddress/updateDefaultAddress', // 设置是否为默认地址
  queryAddressList: '/api/mall/rest/receiverAddress/queryMallReceiverAddress', // 查询所有地址
  queryMallDefaultAddress: '/api/mall/rest/receiverAddress/queryMallDefaultAddress', // 查询默认地址
  findProvince: '/api/mall/rest/receiverAddress/findProvince', // 省
  findCityByProvinceId: '/api/mall/rest/receiverAddress/findCityByProvinceId', // 市
  findDistrictByCityId: '/api/mall/rest/receiverAddress/findDistrictByCityId', // 区
  getVendorAddress: '/api/mall/rest/dynamic/vendorAddress', // 获取档口地址
  queryProvince: '/api/infrastructure/region/provinces', // 获取省份
  queryCity: '/api/infrastructure/region/cities', // 获取城市
  queryDistrict: '/api/infrastructure/region/districts', // 获取区域

  // 进货车
  getShoppingCartList: '/api/mall/rest/shoppingCart/queryV2', // 获取进货车数据
  batchDeleteSku: '/api/mall/rest/shoppingCart/batchDeleteSku', // 删除进货车的sku
  updateSkuNum: '/api/mall/rest/shoppingCart/updateSkuNum', // 保存sku数量
  queryPostAmountDiscount: '/api/mall/rest/shoppingCart/queryPostAmountDiscount', // 首单免运费

  // 上架抖音
  getDouyinFreightTemplateList: '/api/gms/goods/platform/douyin/freightTemplateList',
  getDouyinCategoryProps: '/api/gms/goods/platform/douyin/catePropertyList',
  queryDouyinItemCats: '/api/gms/goods/platform/douyin/queryItemCats',
  saveDouyin: '/api/gms/goods/platform/douyin/saveGoodsPutOnShelf',
  submitDouyin: '/api/gms/goods/platform/douyin/goodsPutonShelf',
  getGoodsCategoryInfo: '/api/gms/goods/getGoodsCategory',
  getDouyinGoodsPutOnShelfDetail: '/api/gms/goods/platform/douyin/getGoodsPutOnShelfDetail',
  getUpdateRule: '/api/gms/goods/platform/douyin/productUpdateRuleQuery', // 根据商品类目获取发布规则

  // PC选款
  logout: '/api/iam/logout', // 退出登录
  getHotWords: '/api/mall/rest/square/anon/hot/search/words', // 获取热搜词
  getSearchHistory: '/api/mall/rest/search/record/findByPage', // 搜索历史
  recordHistory: '/api/mall/rest/search/record/insert', // 记录历史
  clearHistory: '/api/mall/rest/search/record/empty', // 清空搜索历史
  getHotMarkets: '/api/mall/rest/square/anon/markets', // 获取热门市场
  searchByPic: '/api/mall/rest/square/anon/picSearch', // 图搜
  queryMoreCategory: '/api/mall/rest/square/anon/queryMoreCategory', // 获取分类
  getActiveLists: '/api/mall/rest/square/anon/promotions', // 获取活动列表
  queryLayoutGoodsVo: '/api/mall/rest/square/anon/all/queryLayoutGoodsVo', // 获取首页的最后三个（最近上新，同行在选, 猜你喜欢）数据
  getDataList: '/api/mall/rest/square/anon/queryLayoutGoodsVo', // channelType: 1. 为你推荐 2. 最近上新 3.同行都在选
  getStyleOverview: '/api/mall/rest/square/anon/style', // 风格馆
  getShowRoomList: '/api/mall/rest/square/anon/gallery', // 首页展厅
  getSimilarLists: '/api/mall/rest/square/anon/picSearchV2', // searchImg
  getActiveGoods: '/api/mall/rest/promotion/goods', // 获取活动商品  getActiveDetail: '/api/mall/rest/promotion/detail', // 获取活动详情
  getActiveDetail: '/api/mall/rest/square/anon/promotion/detail', // 获取活动详情
  getPageGoods: '/api/mall/rest/square/anon/product/pageGoodsV2', // 搜索款式
  getPageMall: '/api/mall/rest/square/anon/allVendorPage', // 搜索档口
  getTagGroup: '/api/mall/rest/square/anon/tagGroup', // 查询款式或者零售商标签
  getGoodsMarket: '/api/mall/rest/square/anon/goods/markets', // 获取商品热门市场列表
  getActiveListsnum: '/api/mall/rest/square/anon/promotions/num',
  getAllStyles: '/api/mall/rest/square/anon/dict', //  获取全部风格
  getStallCategory: '/api/mall/rest/square/anon/categoryList', // 获取档口页面全部分类
  getShoppingNumUrl: '/api/mall/rest/shoppingCart/findTotalNum',
  getMyStyleWaitNum: '/api/gms/pc/style/count', // 我的款式数
  getMainCategory: '/api/mall/rest/square/anon/mainCategory',
  getPutawayNum: '/api/mall/rest/square/anon/putAway/num',
  getDynamicNum: '/api/mall/rest/dynamic/message/unRead/num',
  getBaoStyle: '/api/mall/rest/square/anon/recommend/goods',
  queryBusinessDistrict: '/api/mall/rest/square/anon/queryBusinessDistrict',
  queryCoreBusinessDistrict: ' /api/mall/rest/square/anon/queryCoreBusinessDistrict',
  getPutAwayVendor: '/api/mall/rest/square/anon/recent/putAway/vendor',
  getRecentGoodsList: '/api/mall/rest/square/anon/recent/goods',
  saveMainCategory: '/api/mall/rest/square/mainCategory/save',
  getCategoryStyle: '/api/mall/rest/square/anon/category/styles', // 获取男女童对应的风格
  getStyleDict: '/api/mall/rest/square/anon/dict',
  findStall: '/api/mall/rest/square/anon/shop/list',

  // 档口详情
  queryVendorStyle: '/api/mall/rest/dynamic/vendorDynamicPage', // 请求档口详情
  queryVendorDynamic: '/api/mall/rest/square/anon/vendorDynamicPage', // 档口动态
  queryVendorDetail: '/api/mall/rest/square/anon/vendorDetail', // 档口详情
  // 档口
  queryCooperationVendor: '/api/mall/rest/dynamic/queryVendorInfo',

  // 售后
  queryReason: '/api/mall/rest/return/order/reasons', // 退货原因
  queryPolicy: '/api/goodsPic/rest/policy/getAliYunPolicyWithOutCheck',
  submitReturnApply: '/api/mall/rest/return/order/returnGoods/confirm',
  queryAfterSaleDetail: '/api/mall/rest/return/order/queryDetailInfo', // 售后订单详情
  queryOrderLog: '/api/mall/operation/rest/admin/Log/getLogs', // 协商记录
  cancelAppli: '/api/mall/rest/return/order/cancel', // 撤销申请
  queryReturnGoods: '/api/mall/rest/return/order/queryReturnGoodsByDeliver', // 查询售后商品
  queryNormalAfterSale: '/api/mall/rest/return/order/queryNormalAfterSale', // 申请售后-是否可申请常规售后查询

  // 店铺订单
  getShopList: '/api/baseinfo/rest/shop/auth/list',
  queryShopOrderList: '/api/saleorder/rest/order/smallCustomer/list',
  invalidateOrders: '/api/saleorder/rest/order/smallCustomer/invalidateOrders', // 作废订单
  getProductDetail: '/api/mall/rest/goods/getGoodsSkuById', // 获取商品详情
  getStatusList: '/api/saleorder/rest/order/smallCustomer/counts', // 状态查询
  getDictList: '/api/boss/baseinfo/rest/filterSet/config',

  // 一件代发
  querySkuInfo: '/api/mall/rest/wholesale/querySkuInfo', // 代发订单ERP获取选款sku信息
  createMallOrder: '/api/mall/rest/wholesale/wholesaleOrdersCreateMallOrder', // 一件代发单生成选款单

  // 展厅
  getExhibitionHallPic: '/api/mall/rest/square/anon/queryPointLocationConfig_10001', // 展厅详情图和跳转链接(临时接口)

  login: '/api/iam/login', // 登录
  smsLogin: '/api/iam/sms/login', // 验证码登录
  sendValidateCode: '/api/iam/validCode/anon/send ', // 发送验证码
  register: '/api/iac/register/anon/retailer/100015',
  getAuthImage: '/api/iam/authImage/anon/getAuthImage', // 获取图片验证码
  getWechatUserInfo: '/api/iam/oauth/anon/userinfo', // 获取微信用户信息
  getNeedBindWechatUserInfo: '/api/iam/login/anon/bind/user/list', // 获取微信用户信息
  wechatBindUsername: '/api/iam/login/anon/bind/username',
  wechatBindMobile: '/api/iam/login/anon/bind/mobile',
  wechatChooseUser: '/api/iam/login/anon/bind/user/choose',

  // 收藏的款式
  collectionList: '/api/mall/rest/goods/collection/pages', // 收藏款式列表
  batchCancelCollect: '/api/mall/rest/goods/collection/batch/cancel', // 批量取消收藏
  collectionShopList: '/api/mall/rest/goods/collection/shop/list',

  // 消息
  getAllMessage: '/api/mall/rest/message/platform/page', // 获取消息列表
  getUnReadMessage: '/api/mall/rest/message/unRead/num', // 未读消息数
  onSelectedMessage: '/api/mall/rest/message/done', // 已读
  switchToErpHome: '/api/iam/module/switch?module=100001&forwardUrl=/egenie-erp-home/index',
  getNotReadMessageStatistics: '/api/boss/baseinfo/notification/rest/message/statistics/notRead', // 统计未读消息数量

  // 直播专区
  getLiveGoodsFloorList: '/api/mall/rest/liveGoods/anon/floorList', // 直播商品管理--楼层列表
  getLiveGoodsAreaPage: '/api/mall/rest/liveGoods/anon/product/liveGoodsAreaPage', // 列表页
  getLiveGoodsArea: '/api/mall/rest/liveGoods/anon/product/liveGoodsArea', // 首页直播专区查询
  // 根据所属模块查询客服信息
  queryCustomerService: '/api/boss/baseinfo/rest/anon/customerService/contact',
  getAdvertList: (advertPlaceManageId: number) => `/api/boss/baseinfo/rest/anon/advert/getAdvertList/${advertPlaceManageId}`, // 获取首页广告列表
  jumpStatistics: (advertManageId: string) => `/api/boss/baseinfo/rest/anon/advert/jumpStatistics/${advertManageId}`, // 点击首页广告跳转统计
};
